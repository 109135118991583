import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = ["list", "thisModal", "close"]

  initialize() {
    var tabEl = document.querySelectorAll('button[data-bs-toggle="pill"]')

    tabEl.forEach(element => {
      element.addEventListener('show.bs.tab', function (event) {
        if ('URLSearchParams' in window) {
          var searchParams = new URLSearchParams(window.location.search)
          searchParams.set("active_tab", event.target.dataset.activeTab);
          var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
          history.pushState(null, '', newRelativePathQuery);
        }
      })
    });
  }

  disableButtonAndChangeText(button) {
    button.setAttribute('disabled', 'disabled');
    button.value = 'Saving...';
  }

  saveRemotely(event) {
    event.preventDefault();
    var activeTabName = document.querySelector('button.nav-link.active').getAttribute('data-active-tab');
    document.querySelector('input#active_tab').value = activeTabName;

    var form = document.querySelector('form.edit_member');
    // THis doesn't seem to work with Chrome for some reason
    // Rails.fire(form, 'submit');
    // form.dispatchEvent(new Event('submit', {bubbles: true}));
    var button = event.currentTarget;
    this.disableButtonAndChangeText(button);

    var otherButton = document.querySelector("form input[name='show'");
    this.disableButtonAndChangeText(otherButton);

    form.submit();
  }

  openModal(event) {
    var thing = event.currentTarget.getAttribute('data-thing');
    var thingName = event.currentTarget.getAttribute('data-thing-name');
    var path = event.currentTarget.getAttribute('data-path');
    var listToUpdate = event.currentTarget.getAttribute('data-list-to-update');
    document.querySelector('#modal-title').innerHTML = 'Create a new ' + thingName;

    var modalErrors = document.querySelector('#modal aside');
    var classNames = 'alert-danger';

    // Clear any errors in the modal
    modalErrors.innerHTML = '';
    modalErrors.classList ? modalErrors.classList.remove(classNames) : modalErrorsclassName.replace(new RegExp('(^|\\b)' + classNames.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');

    fetch('/' + path + '/new?just_form')
    .then((response) => { return response.text() })
    .then((data) => {
        // Inject form in to modal body
        document.querySelector('#modal-body').innerHTML = data;
        // get hold of the form
        var form = document.querySelector('form#new_' + thing);
        // Set attribute so we know what the form is going to update (this is ambiguous if you have
        // say constituencies on two different associations, make it explicit)
        form.setAttribute('data-list-to-update', listToUpdate);
      })
    .catch(err => { console.log(err) });
  }

  addFields(event) {
    var button = event.currentTarget;
    const time = new Date().getTime();
    const regexp = new RegExp(/\[0]/, 'g');
    const regexp2 = new RegExp(/_0_/, 'g');

    var activeDiv = button.closest('div.active');
    var activeDiv = document.querySelector('div.active');
    var list = activeDiv.querySelector('ul');
    var listItem = list.querySelector('li.list-group-item');
    var cloned = listItem.cloneNode('deep');

    // This is to set the id of the association to blank
    var clonedHTMLString = cloned.outerHTML;

    // Replace array id for the form with a timestamp
    var newElementString = clonedHTMLString.replace(regexp, '[' + time + ']');
    newElementString = newElementString.replace(regexp2, '_' + time + '_');

    // uncheck any checkboxes
    newElementString = newElementString.replace(/checked=\"checked\"/, "");
    // unset any date fields
    newElementString = newElementString.replace(new RegExp(/selected=\"selected\"/, 'g'), "");

    // Give the li an id using the timestamp
    const liClassRegExp = new RegExp('li class', 'g');
    var listItemId = 'list-item-' + time;
    newElementString = newElementString.replace(liClassRegExp, 'li ' + 'id="' + listItemId + '" class');

    // Insert new li into the unordered list
    list.insertAdjacentHTML('beforeend', newElementString);

    // Reset all input fields
    const selector = "li#" + listItemId + " input";
    const theInputs = document.querySelectorAll(selector);
    for (const anInput of theInputs) {
      // Don't reset the hidden ID field as existing records need that
      if (anInput.type == 'checkbox' || (anInput.type == 'hidden' && !anInput.id.endsWith('_id'))) {
        anInput.value = "0";
      } else {
        anInput.value = "";
      }
    }

    // Reset all text area fields
    const textAreaSelector = "li#" + listItemId + " textarea";
    const theInputs2 = document.querySelectorAll(textAreaSelector);
    for (const anInput of theInputs2) {
      anInput.value = "";
    }
    event.preventDefault();
}

  onPostError(event) {
    let [data, status, xhr] = event.detail;
    var modalErrors = document.querySelector('#modal aside');
    var classNames = 'alert alert-danger';

    modalErrors.innerHTML = data;
    modalErrors.classList ? modalErrors.classList.add(classNames) : modalErrors.className += ' ' + classNames;
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;

    var thingToUpdate = event.currentTarget.getAttribute('data-list-to-update');
    var listSelects = this.listTargets.filter(element => element.getAttribute('data-thing') == thingToUpdate);

    var lastListSelect = listSelects[listSelects.length - 1]
    // Adding stuff to the list, make sure you've got the right data elements!
    var displayName = data.full_name;
    if (data.full_name === undefined) {
      if (data.name === undefined) {
        // Used for spouses
        displayName = data.first_name + ', ' + data.surname;
      } else {
        displayName = data.name;
      }
    }

    lastListSelect.options[lastListSelect.options.length] = new Option(displayName, data.id);
    lastListSelect.value = data.id;

    this.closeTarget.click();
  }
}
